import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import '../../../../enterprise/src/components/jha-enterprise-institution-selector';
import store from '@banno/platform-ux-shared/session-storage/store.js';
import InstitutionsController from '@banno/platform-ux-shared/controllers/institutions-controller.js';
import UserAccessController from '@banno/platform-ux-shared/controllers/user-access-controller.js';
import router from '@banno/platform-ux-shared/services/router-jh-wcr.js';
import {routingMixin as RouterMixin} from '@jack-henry/web-component-router';
import JhaFetch from '@banno/platform-ux-shared/services/jha-fetch.js';

/**
 * @constructor
 * @polymer
 * @extends {PolymerElement}
 */
const InstitutionsContainerElementBase = RouterMixin(PolymerElement);
/** @polymer */
class InstitutionsContainerElement extends InstitutionsContainerElementBase {
  static get is() {
    return 'institutions-container';
  }
  static get properties() {
    return {
      buttonLoading: {
        type: Boolean,
        value: false
      },
      institutions: Array,
      selectedInstitutionId: String
    };
  }

  connectedCallback() {
    super.connectedCallback();
    (async () => {
      try {
        // The dashboard should guarantee that a user exists
        const institutions = await InstitutionsController.loadInstitutions((store.user || {}).userId);
        this.institutions = institutions.sort((a, b) => {
          const nameA = a.institution.name.toUpperCase();
          const nameB = b.institution.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          return nameA > nameB ? 1 : 0;
        });
        const selectedInstitution = this.institutions.find((instData) => instData.selected);
        if (selectedInstitution) {
          this.selectedInstitutionId = selectedInstitution.institution.institutionId;
        }
      } catch (e) {
        this._fireToast('toastInstitutionsFailedLoad');
        this.institutions = [];
      }
    })();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.buttonLoading = false;
  }

  // temporary method during conversion to authz
  async _saveSelectedInstitutionSentry(institutionId) {
    try {
      await JhaFetch.fetch({
        api: {
          url: `/a/sentry/api/users/${store.user.userId}/selected-institutions`
        },
        method: 'DELETE'
      });
    } catch (e) { }
    try {
      await JhaFetch.fetch({
        api: {
          url: `/a/sentry/api/users/${store.user.userId}/selected-institutions`
        },
        method: 'PUT',
        body: {
          institutionIds: [institutionId]
        }
      });
    } catch (e) {}
    return true;
  }

  async _saveSelectedInstitution(institutionId) {
    try {
      this.buttonLoading = true;
      const institutionApps = await UserAccessController.addUserInstitution(institutionId);
      store.addAppsList(institutionId, institutionApps.applications);
      let selectedInstitution;
      this.institutions.forEach((instData) => {
        if (instData.institution.institutionId === institutionId) {
          instData.selected = true;
          selectedInstitution = instData;
        } else {
          instData.selected = false;
        }
      });
      this.selectedInstitution = selectedInstitution;
      const storeInstitutions = store.institutions;
      storeInstitutions.forEach((instData) => {
        instData.selected = instData.institution.institutionId === institutionId;
      });
      store.institutions = storeInstitutions; // forces session storage to update
    } catch (error) {
      this.buttonLoading = false;
    }
  }

  _changeInstitution(event) {
    this.selectedInstitutionId = event.detail.institutionId;
    this._closeInstitutions(event);
  }

  async _closeInstitutions(event) {
    if (this.selectedInstitutionId) {
      const selectedInstitution =
        (this.institutions.find((instData) => instData.institution.institutionId === this.selectedInstitutionId) ||
        {});
      if (!selectedInstitution.selected) {
        try {
          await Promise.all([
            this._saveSelectedInstitution(selectedInstitution.institution.institutionId),
            this._saveSelectedInstitutionSentry(selectedInstitution.institution.institutionId)
          ]);
        } catch (e) { }
      }
      router.go(`/a/${selectedInstitution.institution.shortId}`);
    }
  }

  _fireToast(toastId) {
    this.dispatchEvent(new CustomEvent('toast', {
      bubbles: true,
      composed: true,
      detail: toastId
    }));
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
        width: 90%;
        min-height: 0;
        max-height: none;
        max-width: 550px;
        border-radius: 2px;
        box-shadow: 0 3px 12px 0 rgba(37,49,62,.24),0 3px 24px rgba(37,49,62,.12);
        border: none;
        margin: auto;
        padding: 0;
        background: white;
      }
      @media (max-width: 480px) {
        :host {
          width: 100%;
          height: 100%;
          border-radius: 0;
        }
      }
    </style>
    <jha-enterprise-institution-selector isAuth=""></jha-enterprise-institution-selector>
  `;
  }
}
customElements.define(InstitutionsContainerElement.is, InstitutionsContainerElement);
export default InstitutionsContainerElement;
